<template>
  <div class="" id="organizer">
    <Preloader v-if="!loaded" />
    <div class="container-fluid">
      <div class="row pt-2">
        <div class="col-12 ps-2 pe-2">
          <h3 class="bg-secondary rounded p-1 ps-2 text-white text-start" style="font-family: Rockwell">Organizer List</h3>
        </div>
        <div class="col-md-6 ps-2 pe-2" v-for="(organizer,key) in organizers" :key="key">
          <div class="card mb-3 organizer-bg">
            <div class="row g-0">
              <div class="col-md-4 bg-white p-1">
                <img :src="organizer.photo" class="organizer-img img-fluid rounded-start" alt="Image">
              </div>
              <div class="col-md-8 text-start">
                <div class="card-body" style="font-family: Rockwell">
                  <h5 class="card-title fw-bold">{{organizer.name}}</h5>
                  <h6 class="card-title fw-bold">{{organizer.title}}</h6>
                  <p class="card-text">{{organizer.description}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Preloader from "../components/Preloader";
  export default {
    name: "Organizer",
    components: {Preloader},
    data (){
      return {
        organizers:[],
        loaded:false
      }
    },
    created() {
      this.$http.get("get-organizers").then(function (response) {this.organizers = response.body; this.loaded = true;});
    }
  }
</script>

<style scoped>
  .organizer-bg{
    background-color: #edfff6;
  }
  .organizer-img{
    width: 200px !important;
    height: 200px !important;
  }
</style>